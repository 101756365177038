/* eslint-disable no-unused-vars */
import React from 'react'

import { BreadcrumbJsonLd, ProductJsonLd } from 'next-seo'

const JsonLD = ({ initialPagesContext }) => {
  return false
}

export default JsonLD
