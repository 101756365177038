import { React, useEffect, useState } from 'react'

import { NextSeo } from 'next-seo'
import Head from 'next/head'

import useRecentHistory from 'Clutch/Hooks/useRecentHistory'

const HeadContent = ({ initialPagesContext, isSandbox }) => {
  // eslint-disable-next-line no-unused-vars
  const [seoData, setSeoData] = useState(initialPagesContext.seoData)

  if (initialPagesContext.slug == 'error' && typeof window !== 'undefined') {
    throw 'Error on Purpose'
  }
  useEffect(() => {
    if (initialPagesContext.seoData.isDefault) {
      console.error(
        `pages/${initialPagesContext.slug} has no specific seo data, using the default`,
      )
    }
  }, [])

  useEffect(() => {
    try {
      useRecentHistory.AddToRecentHistory({
        imageUrl: seoData?.ogImage,
        title: `Article: ${(seoData?.title ?? '').split('|')[0].trim()}`,
      })
    } catch (err) {
      console.error('Error pushing to recent history in PRP', err)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new CustomEvent('clarityautomate.initialized'))
    }
  })

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="https://app.maker.co/enhance/speedway.js"
          as="script"
        />
        <link rel="icon" href="/favicon.png" type="image/png" />
      </Head>

      <NextSeo
        title={seoData.title}
        description={seoData.metaDescription}
        canonical={
          !isSandbox
            ? `${initialPagesContext.domain}/pages/${initialPagesContext.slug}`
            : null
        }
        noindex={isSandbox || !initialPagesContext.seoData.index}
        nofollow={isSandbox || !initialPagesContext.seoData.follow}
        openGraph={{
          url: !isSandbox
            ? `${initialPagesContext.domain}/pages/${initialPagesContext.slug}`
            : null,
          title: initialPagesContext.seoData.title,
          description: initialPagesContext.seoData.metaDescription,
          images: initialPagesContext.seoData.ogImage
            ? [
                {
                  url: initialPagesContext.seoData.ogImage || null,
                  width: `${initialPagesContext.seoData.ogImageWidth}`,
                  height: `${initialPagesContext.seoData.ogImageHeight}`,
                  alt: initialPagesContext.seoData.title,
                },
              ]
            : null,
        }}
      />
    </>
  )
}

export default HeadContent
